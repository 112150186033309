import axios from 'axios'

export default {
    install: (app) => {
        let token_tag = document.head.querySelector('meta[name="csrf-token"]')
        if (token_tag) {
            axios.defaults.headers.common['X-CSRF-TOKEN'] = token_tag.content
            app.config.globalProperties.$csrf_token = token_tag.content
        }

        axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

        app.config.globalProperties.axios = axios
    },
};