import 'vuetify/styles'
import { createVuetify } from "vuetify";
import * as components from 'vuetify/components'
import * as labsComponents from 'vuetify/labs/components'
import * as directives from 'vuetify/directives'
import "@mdi/font/css/materialdesignicons.min.css";

const customDarkTheme = {
    dark: true,
    colors: {
        background: "#15202b",
        surface: "#263238",
        primary: "#0b681e",
        secondary: "#E0E0E0",
        error: "#f44336",
        info: "#1d953b",
        success: "#4caf50",
        warning: "#cc7d25",
    },
};

export default createVuetify({
    components: {
        ...components,
        ...labsComponents,
        directives,
    },
    theme: {
        defaultTheme: "customDarkTheme",
        themes: {
            customDarkTheme,
        },
    },
    icons: {
        defaultSet: 'mdi', // This is already the default value - only for display purposes
    },
});